
import { Component, Prop } from 'vue-property-decorator';
import ListComponent from '@/shared/components/layout/list/list-component';
import { ModuleProps } from '@/shared/state/template/module-props';
import ItemsTable from '@/shared/components/layout/list.vue';
import { ListFilter } from '@/shared/types';

    // module
import { contactModule } from '../../shared/state/contacts/module';
    // model
import { Contact } from '../../shared/models/contact/contact';
    // data request type
import { ContactDataRequest } from '@/modules/contractors/shared/requests/contact/contact-data-request';
    // headers
import { contactListHeaders } from '@/modules/contractors/shared/config';

    // components
import ContactForm from './form.vue';

@Component({
        components: {
            ItemsTable,
            ContactForm,
        },
    })
    export default class ContactList extends ListComponent<
        Contact,
        ContactDataRequest
    > {
        @Prop(Number) public contractorId!: number;
        public headers: object[] = contactListHeaders;
        public store = this.$store.state.contractorsState.contactsState;
        public actionsTypes = contactModule.actionsTypes;
        public mutationTypes = contactModule.mutationsTypes;
        public fetchAction = this.actionsTypes.FETCH_DATA;
        public removeAction = this.actionsTypes.REMOVE_ITEM;
        public props: ModuleProps = contactModule.moduleProps;

        public mounted() {
            this.id = this.contractorId;
        }
    }
