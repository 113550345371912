
import { Component, Prop } from 'vue-property-decorator';
import FormComponent from '@/shared/components/layout/form/form-component';
import FormActions from '@/shared/components/layout/form/form-actions.vue';

// module
import { contactModule } from '../../shared/state/contacts/module';
// data request type
import { ContactDataRequest } from '@/modules/contractors/shared/requests/contact/contact-data-request';

import AutoCompleteWithAddNew from '@/shared/components/elements/auto-complete-with-add-new.vue';
import { positionTypesModule } from '../../shared/state/settings/position-types/module';
import PositionTypesForm from '../settings/position-types/form.vue';

import { callSuper } from '@/shared/helpers';
import ContractorForm from '../form.vue';
import { contractorsModule } from '../../shared/state/module';

@Component({
    components: { FormActions, AutoCompleteWithAddNew },
})
export default class ObjectsTypesSettingsForm extends FormComponent<ContactDataRequest> {
    @Prop(Number) public contractorId!: number;

    public actionsTypes = contactModule.actionsTypes;
    public mutationTypes = contactModule.mutationsTypes;
    public store = this.$store.state.contractorsState.contactsState;
    public storeAction = this.actionsTypes.STORE_ITEM;
    public updateAction = this.actionsTypes.UPDATE_ITEM;
    public requestData = new ContactDataRequest();
    public contactModule = contactModule;

    // data required for add new type
    public positionTypesModuleState = this.$store.state.contractorsState.positionTypesModuleState;
    public positionTypesModule = positionTypesModule;
    public contractorsState = this.$store.state.contractorsState;

    public PositionTypesForm = PositionTypesForm;
    public ContractorForm = ContractorForm;
    public contractorsModule = contractorsModule;

    get formTitle(): string {
        return this.edit ? 'Edytuj kontakt' : 'Dodaj kontakt';
    }

    public initForm() {
        this.form = this.$refs.createContactForm;
    }

    // to delete email or phone
    public deleteItemFromArray(item: string, propertyName: string) {
        if (this.itemData[propertyName] && this.itemData[propertyName].length > 0) {
            this.itemData[propertyName] = this.itemData[propertyName].filter((e: any) => !this._.isEqual(item, e));
        }
    }
}
