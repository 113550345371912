

    import {Component, Vue} from 'vue-property-decorator';
    import {mdiEmail, mdiPhone} from '@mdi/js';

    @Component({
        props: {
            item: Object,
        },
    })
    export default class TitleBar extends Vue {
        public item!: any;

        public icons: object = {
            mdiEmail,
            mdiPhone,
        };

        get phoneUrl(): string {
            return this.item && this.item.phone ? 'tel:' + String(this.item.phone) : '';
        }

        get emailUrl(): string {
            return this.item && this.item.email ? 'mailto:' + String(this.item.email) : '';
        }
    }
