
import ItemsTable from '@/shared/components/layout/list.vue';
import { Component } from 'vue-property-decorator';
import ListComponent from '@/shared/components/layout/list/list-component';
import { InvestmentDataRequest } from '@/modules/investments/shared/requests/investment-data-request';
import InvestmentsForm from '@/modules/investments/components/form.vue';
import { ModuleProps } from '@/shared/state/template/module-props';


// state module
import { positionTypesModule } from '../../../shared/state/settings//position-types/module';

// dataResponse
import { PositionType } from '../../../shared/models/settings/positions-type';

// dataRequest
import { PositionTypeDataRequest } from '../../../shared/requests/settings/positions-type-data-request';

// headers
import { positionTypesListHeaders } from '../../../shared/config/data-settings';

// form
import PositionTypeForm from './form.vue';

@Component({
    components: {
        ItemsTable,
        PositionTypeForm,
    },
})
export default class ObjectsTypesSetting extends ListComponent<
    PositionType,
    PositionTypeDataRequest
> {
    public headers: object[] = positionTypesListHeaders;
    public actionsTypes = positionTypesModule.actionsTypes;
    public mutationTypes = positionTypesModule.mutationsTypes;
    public fetchAction: string = this.actionsTypes.FETCH_DATA;
    public removeAction: string = this.actionsTypes.REMOVE_ITEM;
    public store: any = this.$store.state.contractorsState
        .positionTypesModuleState;
    public props: ModuleProps = positionTypesModule.moduleProps;


}
