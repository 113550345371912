
import Vue from 'vue';
import Component from 'vue-class-component';
import { contrast, hexToRgb } from '@/shared/helpers/colors';
import NameReduce from '@/shared/components/elements/name-reduce.vue';
import { salesModule } from '@/modules/sales/shared';
import ActionConfirmDialog from '@/shared/components/dialogs/action-confirm-dialog.vue';
import UserPicker from '@/modules/sales/components/pickers/user-picker.vue';
import StatusPicker from '@/modules/sales/components/pickers/status-picker.vue';
import { Inject, Prop, Watch } from 'vue-property-decorator';
import { SalesState } from '../../shared/state/state';
import { leadSources } from '@/shared/config/sales';
import TextContrast from '@/shared/components/elements/text-contrast.vue';
import { Investment } from '@/modules/investments/shared/models/investment';

@Component({
    props: {
        name: String,
        description: String,
        priority: Object,
        created_at: String,
        id: Number,
        due_date: String,
        reporter: Object,
        assigned_user: Object,
        assigned_user_id: Number,
        status: Object,
        investment_subject: Object,
    },
    components: {
        TextContrast,
        NameReduce,
        ActionConfirmDialog,
        UserPicker,
        StatusPicker,
    },
})
export default class Item extends Vue {
    @Inject() public readonly permissionCheck!: (...permissions: string[]) => boolean;

    @Prop(Object) public investment!: Investment;
    @Prop(String) public source!: string;

    get currentUser() {
        return this.$store.state.authState.user;
    }

    get isAnySaleDragged() {
        return this.store.isAnySaleDragged;
    }

    public store: SalesState = this.$store.state.salesState;
    public actionsTypes = salesModule.actionsTypes;
    public assignedUserLoading = false;
    public removeDialog = false;
    public assignedUser = {};

    private whiteColor = hexToRgb('#ffffff');
    private blackColor = hexToRgb('#000000');

    public mounted() {
        this.assignedUser = this.$props.assigned_user;
    }

    public showRemoveDialog() {
        this.removeDialog = true;
    }

    public closeRemoveDialog() {
        this.removeDialog = false;
    }

    public deleteSale() {
        this.$store.dispatch(this.actionsTypes.REMOVE_ITEM, this.$props.id);
        this.$emit('remove');
        this.removeDialog = false;
    }

    public goToDetails() {
        this.$router.push(`/sales/${this.$props.id ? this.$props.id : ''}`);
    }

    get leadSource() {
        const source = leadSources.find((e) => e.id === this.source);
        return source
            ? source
            : {
                  id: 'unknown',
                  name: 'Nieznane',
                  color: '#ccc',
                  icon: 'mdi-help',
              };
    }

    public assignUser(e: any) {
        this.assignedUserLoading = true;
        e.then((res: any) => {
            this.$store.commit('SHOW_SNACKBAR', {
                type: 'success',
                text: `Osoba została przypisana do zadania`,
            });
            this.assignedUser = res.data.data.assigned_user;
            this.$emit('update:assigned_user', this.assignedUser);
            this.$emit('update:assigned_user_id', res.data.data.assigned_user);
        })
            .catch((err: any) => {
                this.$store.commit('SHOW_SNACKBAR', { type: 'error', text: 'Coś poszło nie tak' });
            })
            .finally(() => {
                this.assignedUserLoading = false;
            });
    }

    public removeAssignedUser() {
        this.assignedUserLoading = true;
        this.$store
            .dispatch(this.actionsTypes.UPDATE_ITEM, {
                id: this.$props.id,
                assigned_user_id: null,
            })
            .then((val) => {
                this.assignedUser = val.data.assigned_user;
                this.assignedUserLoading = false;
            });
    }

    public isDark(color: string) {
        return contrast(hexToRgb(color), this.whiteColor) > 2;
    }

    public isLight(color: string) {
        return contrast(hexToRgb(color), this.blackColor) > 2;
    }
}
