
import { Component } from 'vue-property-decorator';
import AddressField from '@/shared/components/elements/address.vue';
import DetailsComponent from '@/shared/components/layout/details/details-component';
import TopToolbar from '@/shared/components/layout/details/top-toolbar.vue';

import { contactModule } from '@/modules/contractors/shared/state/contacts/module';
import { ContactDataRequest } from '@/modules/contractors/shared/requests/contact/contact-data-request';

import NotesList from '../notes/list.vue';
import ContactForm from './form.vue';

@Component({
    props: {
        id: String,
    },
    components: {
        TopToolbar,
        NotesList,
        ContactForm,
    },
})
export default class ContactDetails extends DetailsComponent<ContactDataRequest> {
    public store = this.$store.state.contractorsState.contactsState;
    public actionsTypes = contactModule.actionsTypes;
    public mutationsTypes = contactModule.mutationsTypes;
    public showAction = this.actionsTypes.SHOW_ITEM;
    public tab: any = null;

    get editedItem() {
        return this.store.editedItem;
    }

    get formDialog() {
        return this.store.formDialog;
    }
    set formDialog(newVal) {
        this.$store.commit(this.mutationsTypes.UPDATE_FORM_DIALOG, newVal);
    }

    public created() {
        this.fetchDetails();
    }


    public editItem() {
        this.$store.commit(this.mutationsTypes.UPDATE_EDITED, this.item);
        this.$store.commit(this.mutationsTypes.UPDATE_FORM_DIALOG, true);
    }

    public itemSaved() {
        this.formDialog = false;
        this.fetchDetails();
    }

    public zipWithSeparator(zip: string) {
        if (zip && typeof zip === 'string') {
            return [zip.slice(0, 2), zip.slice(2)].join('-');
        }
    }
}
