export class ContactDataRequest {
    public id: number = 0;
    public name: string = '';
    public emails: [any] | [] = [];
    public phones: [any] | [] = [];
    public nip?: string = '';
    public address: string = '';
    public zip: string = '';
    public city: string = '';
    public region: string = '';
    public country: string = '';
    public description: string = '';

    // tslint:disable-next-line:variable-name
    public contractor_contact_position_id: string | null = null;
    // tslint:disable-next-line:variable-name
    public contractor_id: number = 0;
    [key: string]: any;
}
