
import {Component} from 'vue-property-decorator';
import FormComponent from '@/shared/components/layout/form/form-component';
import FormActions from '@/shared/components/layout/form/form-actions.vue';

// state module
import {positionTypesModule} from '../../../shared/state/settings//position-types/module';

// dataRequest
import {PositionTypeDataRequest} from '../../../shared/requests/settings/positions-type-data-request';
import { callSuper } from '@/shared/helpers';

@Component({
    components: {FormActions},
})
export default class PositionTypesForm extends FormComponent<PositionTypeDataRequest> {
    public actionsTypes = positionTypesModule.actionsTypes;
    public store = this.$store.state.contractorsState
        .positionTypesModuleState;
    public storeAction = this.actionsTypes.STORE_ITEM;
    public updateAction = this.actionsTypes.UPDATE_ITEM;
    public requestData = new PositionTypeDataRequest();

    public created() {
        //
    }

    get formTitle(): string {
        return this.edit
            ? 'Edytuj typ pozycji'
            : 'Dodaj typ pozycji';
    }

    public initForm() {
        this.form = this.$refs.createDepartmentForm;
    }
}
