
    import ItemsTable from '@/shared/components/layout/list.vue';
    import { Component } from 'vue-property-decorator';
    import ListComponent from '@/shared/components/layout/list/list-component';
    import { InvestmentDataRequest } from '@/modules/investments/shared/requests/investment-data-request';
    import InvestmentsForm from '@/modules/investments/components/form.vue';
    import { ModuleProps } from '@/shared/state/template/module-props';

    // state module
    import { contractorsTypesModule } from '../../../shared/state/settings/contractor-types/module';

    // dataResponse
    import { ContractorType } from '../../../shared/models/settings/contractors-type';

    // dataRequest
    import { ContractorTypeDataRequest } from '../../../shared/requests/settings/contactors-type-data-request';

    // headers
    import { contractorsTypesListHeaders } from '../../../shared/config/data-settings';

    // form
    import ContractorTypeForm from './form.vue';

    @Component({
        components: {
            ItemsTable,
            ContractorTypeForm,
        },
    })
    export default class ObjectsTypesSetting extends ListComponent<
        ContractorType,
        ContractorTypeDataRequest
    > {
        public headers: object[] = contractorsTypesListHeaders;
        public actionsTypes = contractorsTypesModule.actionsTypes;
        public fetchAction: string = this.actionsTypes.FETCH_DATA;
        public removeAction: string = this.actionsTypes.REMOVE_ITEM;
        public mutationTypes = contractorsTypesModule.mutationsTypes;
        public store: any = this.$store.state.contractorsState
            .contractorsTypesModuleState;
        public props: ModuleProps = contractorsTypesModule.moduleProps;
    }
