
import { Component } from 'vue-property-decorator';
import FormComponent from '@/shared/components/layout/form/form-component';
import FormActions from '@/shared/components/layout/form/form-actions.vue';
import AutoCompleteWithAddNew from '@/shared/components/elements/auto-complete-with-add-new.vue';
import { contractorsModule } from '../../shared/state/module';
import ContractorForm from '../form.vue';
import { RelatedInvestmentDataRequest } from '../../shared/requests/related-investments-data-request';
import { relatedInvestmentsModule } from '../../shared/state/related-investments/module';
import { investmentsModule } from '@/modules/investments/shared/state/module';
import { callSuper } from '@/shared/helpers';

@Component({
    components: { FormActions, AutoCompleteWithAddNew },
})
export default class RelatedInvestmentsForm extends FormComponent<RelatedInvestmentDataRequest> {
    public actionsTypes = relatedInvestmentsModule.actionsTypes;
    public mutationTypes = relatedInvestmentsModule.mutationsTypes;
    public store = this.$store.state.contractorsState.relatedInvestmentsState;
    public storeAction = this.actionsTypes.STORE_ITEM;
    public updateAction = this.actionsTypes.UPDATE_ITEM;
    public requestData = new RelatedInvestmentDataRequest();

    public contractorsState = this.$store.state.contractorsState;
    public contractorsModule = contractorsModule;
    public ContractorForm = ContractorForm;

    public investmentsStore = this.$store.state.investmentsState;
    public investmentsActionTypes = investmentsModule.actionsTypes;

    get formTitle(): string {
        return 'Edytuj powiązane inwestycje';
    }

    get investments() {
        return this.investmentsStore.dataList;
    }

    get investmentsLoading() {
        return this.investmentsStore.loadingList;
    }

    public created() {
        this.fetchInvestments();
    }

    public initForm() {
        this.form = this.$refs.createContactForm;
        callSuper(this, 'mounted');
    }

    public fetchInvestments() {
        this.$store.dispatch(this.investmentsActionTypes.FETCH_DATA, { simple: true });
    }
}
