
    import ItemsTable from '@/shared/components/layout/list.vue';
    import { Component } from 'vue-property-decorator';
    import ListComponent from '@/shared/components/layout/list/list-component';
    import { ModuleProps } from '@/shared/state/template/module-props';

    // state module
    import { contractorsSpecializationsModule } from '../../../shared/state/settings/contractor-specializations/module';

    // dataResponse
    import { ContractorSpecialization } from '../../../shared/models/settings/contractors-specialization';

    // dataRequest
    import { ContractorSpecializationDataRequest } from '../../../shared/requests/settings/contractors-specialization-data-request';

    // headers
    import { contractorsSpecializationsListHeaders } from '../../../shared/config/data-settings';

    // form
    import ContractorsSpecializationsForm from './form.vue';

    @Component({
        components: {
            ItemsTable,
            ContractorsSpecializationsForm,
        },
    })
    export default class ContractorsSpecializationsList extends ListComponent<
        ContractorSpecialization,
        ContractorSpecializationDataRequest
    > {
        public headers: object[] = contractorsSpecializationsListHeaders;
        public actionsTypes = contractorsSpecializationsModule.actionsTypes;
        public fetchAction: string = this.actionsTypes.FETCH_DATA;
        public removeAction: string = this.actionsTypes.REMOVE_ITEM;
        public mutationTypes = contractorsSpecializationsModule.mutationsTypes;
        public store: any = this.$store.state.contractorsState
            .contractorsSpecializationsModuleState;
        public props: ModuleProps = contractorsSpecializationsModule.moduleProps;
    }
