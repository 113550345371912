
import { Component, Prop, Watch} from 'vue-property-decorator';
import FormComponent from '@/shared/components/layout/form/form-component';
import FormActions from '@/shared/components/layout/form/form-actions.vue';
import { RelatedInvestmentSubjectDataRequest } from '../../shared/requests/related-investment-subjects-data-request';
import { contractorsModule } from '../../shared/state/module';
import { Contractor } from '../../shared/models/contractor';
import { reservationDurations } from '@/shared/config/investments';
import AutoCompleteWithAddNew from '@/shared/components/elements/auto-complete-with-add-new.vue';
import DatePicker from '@/shared/components/elements/date-picker.vue';
import CardPicker from '@/shared/components/layout/form/card-picker.vue';
import { callSuper } from '@/shared/helpers';
import { relatedInvestmentsModule } from '../../shared/state/related-investments/module';
import { investmentSubjectOperationsModule } from '@/modules/investments/shared/state/submodules';
import SubjectCard from '@/modules/investments/components/subjects/subject-card.vue';


@Component({
    components: {
        FormActions,
        DatePicker,
        AutoCompleteWithAddNew,
        CardPicker,
        SubjectCard,

    }
})

export default class RelatedInvestmentSubjectsForm extends FormComponent<RelatedInvestmentSubjectDataRequest> {
    @Prop(Number) public parentId!: number;
    @Prop(Boolean) public addReservation!: boolean;
    public actionsTypes = investmentSubjectOperationsModule.actionsTypes;
    public mutationTypes = relatedInvestmentsModule.mutationsTypes;
    public storeAction = this.actionsTypes.STORE_ITEM;
    public updateAction = this.actionsTypes.UPDATE_ITEM;
    public requestData = new RelatedInvestmentSubjectDataRequest();
    public clients: any = [];
    public reservingClientLoading: boolean = false;
    public reservationDurations = reservationDurations;
    public reservationDuration = 0;
    public subjectsStore = this.$store.state.investmentsState.subjectOperationsState;
    public subjectActions = investmentSubjectOperationsModule.actionsTypes;


    get formTitle(): string {
        return this.addReservation ? 'Edytuj rezerwację' : 'Dodaj rezerwację';
    }

    get isLoading() {
        return this.$store.state.contractorsState.relatedInvestmentSubjectsState.loadingItem;
    }

    set isLoading(val: boolean) {
        this.$store.state.contractorsState.relatedInvestmentSubjectsState.loadingItem = val;
    }

    public setLoading() {
        this.isLoading = !this.isLoading;
    }

    public created() {
        this.reservingClientLoading = true;
        this.$store
        .dispatch(contractorsModule.actionsTypes.FETCH_DATA, { filters: {is_company: false }})
        .then((res) => {
            this.clients = res.data.filter((contractor: Contractor) => contractor.type.name === 'Klient');
        })
        .finally(() => {
            this.reservingClientLoading = false;
        });
        if (!this.edit) {
            this.itemData.reserving_client_id = this.parentId;
        }
    }

    public onReservationStartDateChange(value: string) {
        if (this.reservationDuration === 0) {
            this.itemData.reservation_start_date = this.parseDate(value);
        } else
        if (this.edit) {
            this.itemData.reservation_start_date = this.parseDate(value);
            if (this.reservationDuration !== 0) {
                const reservationEndDate = new Date(value);
                reservationEndDate.setDate(reservationEndDate.getDate() + this.reservationDuration);
                this.itemData.reservation_end_date = this.parseDate(reservationEndDate);
            }
        } else
        if (!this.edit) {
            const reservationEndDate = new Date(value);
            reservationEndDate.setDate(reservationEndDate.getDate() + this.reservationDuration);

            this.itemData.reservation_start_date = this.parseDate(value);

            this.itemData.reservation_end_date = this.parseDate(reservationEndDate);
        }
        this.$forceUpdate();
    }

    public onReservationEndDateChange(value: string) {
        this.itemData.reservation_end_date = this.parseDate(value);
    }

    public onReservationDurationChange() {
        const reservationEndDate = new Date(this.itemData.reservation_start_date);
        reservationEndDate.setDate( reservationEndDate.getDate() + this.reservationDuration);

        this.$set(this.itemData, 'reservation_end_date', this.parseDate(reservationEndDate));
    }

    public initForm() {
        this.form = this.$refs.createDepartmentForm;
    }

    @Watch('valid')
    public onValidationFail() {
        if (!this.valid) {
            this.isLoading = false;
        }
    }

}

