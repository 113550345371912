
import {Component} from 'vue-property-decorator';
import FormActions from '@/shared/components/layout/form/form-actions.vue';
import {InvestmentDataRequest} from '@/modules/investments/shared/requests/investment-data-request';
import DatePicker from '@/shared/components/elements/date-picker.vue';
import {callSuper, parseDate} from '@/shared/helpers';
import FormComponent from '@/shared/components/layout/form/form-component';
import SelectTree from '@/shared/components/elements/select-tree.vue';
import {investmentsModule} from '@/modules/investments/shared/state/module';
import {investmentTagsModule} from '@/modules/investments/shared/state/submodules';
import {InvestmentTag} from '@/modules/investments/shared/models/investment-tag';
import ActionConfirmDialog from '@/shared/components/dialogs/action-confirm-dialog.vue';
import InvestmentTagChip from '@/modules/investments/components/settings/tags/tag.vue';
import ObjectsTypesSettingsForm from '@/modules/investments/components/settings/rooms/form.vue';
import {landModule} from '@/modules/lands/shared/state/module';
import {prospectMediaAvailable} from '@/shared/config/investments';
import Wysiwyg from '@/shared/components/documents/wysiwyg.vue';
import TitleCard from '@/modules/investments/components/objects/subjects/prospect/title-card.vue';
import TagsInput from '@/shared/components/elements/tags-input.vue';

@Component({
    components: {
        InvestmentTagChip,
        ActionConfirmDialog,
        DatePicker,
        FormActions,
        SelectTree,
        Wysiwyg,
        TitleCard,
        TagsInput,
    },
})
export default class InvestmentForm extends FormComponent<InvestmentDataRequest> {
    public store: any = this.$store.state.investmentsState;
    public requestData = new InvestmentDataRequest();
    public actionsTypes = investmentsModule.actionsTypes;
    public tagActions = investmentTagsModule.actionsTypes;
    public tagMutations = investmentTagsModule.mutationsTypes;
    public newTagDialog: boolean = false;
    public storeAction: string = this.actionsTypes.STORE_ITEM;
    public updateAction: string = this.actionsTypes.UPDATE_ITEM;
    public tagsSearch: any = null;
    public landsSearch: any = null;
    public searchTag: string = '';
    public searchLand: string = '';
    public parseDate = parseDate;
    public InvestmentsForm = ObjectsTypesSettingsForm;
    public currentTab = 0;
    public mediaAvailable = prospectMediaAvailable;
    public landsActions = landModule.actionsTypes;
    public readMore: boolean = false;

    public legalActList = [
        'Studium uwarunkowań i kierunków zagospodarowania przestrzennego gminy',
        'Miejscowy plan zagospodarowania przestrzennego',
        'Miejscowy plan rewitalizacji',
        'Miejscowy plan odbudowy',
        'Inne*',
    ];

    get formTitle() {
        return this.edit ? 'Edytuj inwestycję' : 'Dodaj inwestycję';
    }

    public created() {
        this.$store.dispatch(this.tagActions.FETCH_DATA, {simple: false});
        this.$store.dispatch(this.landsActions.FETCH_DATA, {simple: false});

        if (!this.itemData.tags) {
            this.itemData.tags = [];
        }
    }

    get lands() {
        return this.$store.state.landsState.data;
    }

    get landsLoading() {
        return this.$store.state.landsState.loading;
    }

    public initForm() {
        this.initFocus();
        this.form = this.$refs.createInvestmentForm;
    }

    public searchLands(search: string) {
        clearTimeout(this.landsSearch);

        this.landsSearch = setTimeout(() => {
            if (search && search.length >= 3) {
                this.$store.dispatch(this.landsActions.FETCH_DATA, {filters: {search}});
            }
        }, 500);
    }

    public async submitForm(e: any, next?: boolean): Promise<void> {
        await this.form.validate();

        if (this.itemData && this.valid && this.itemData.tags) {
            this.itemData.tags = this.itemData.tags.map((tag: InvestmentTag) => tag.id);
        }

        return callSuper(this, 'submitForm', e, next);
    }

    public initFocus() {
        this.searchTag = '';
        this.$store.dispatch(this.tagActions.FETCH_DATA, {simple: false});
        this.$nextTick(() => {
            const nameField: any = this.$refs.investmentFormNameField;
            if (nameField) {
                nameField.focus();
            }
        });
    }

    get startBefore() {
        if (typeof this.itemData.start_date === 'string' && this.itemData.start_date.length === 0) {
            return true;
        } else {
            return (v: string) =>
                this.itemData.start_date &&
                v &&
                (!this.itemData.end_date ||
                    new Date(this.itemData.start_date) <= new Date(this.itemData.end_date) ||
                    'Data zakończenia nie może być wcześniej niż data rozpoczęcia');
        }
    }

    get endAfter() {
        if (typeof this.itemData.end_date === 'string' && this.itemData.end_date.length === 0) {
            return true;
        } else {
            return (v: string) =>
                this.itemData.end_date &&
                v &&
                (!this.itemData.start_date ||
                    new Date(this.itemData.start_date) <= new Date(this.itemData.end_date) ||
                    'Data zakończenia nie może być wcześniej niż data rozpoczęcia');
        }
    }

    get startPlannedBefore() {
        if (typeof this.itemData.planned_start_date === 'string' && this.itemData.planned_start_date.length === 0) {
            return true;
        } else {
            return (v: string) =>
                !this.itemData.planned_start_date ||
                !v ||
                new Date(this.itemData.planned_start_date) <= new Date(this.itemData.planned_end_date) ||
                'Planowana data zakończenia nie może być wcześniej niż data rozpoczęcia';
        }
    }
}
