export class RelatedInvestmentSubjectDataRequest {
    public id: number = 0;
    public uuid: string = '';
    public name: string = '';
    public code: string = '';
    public description: string = '';
    public price: number = 0;
    // tslint:disable-next-line:variable-name
    public public_status: string = '';
    // tslint:disable-next-line:variable-name
    public private_status: string = '';
    // tslint:disable-next-line:variable-name
    public reserving_client_id: number = 0;
    // tslint:disable-next-line:variable-name
    public reservation_start_date: string = '';
    // tslint:disable-next-line:variable-name
    public reservation_end_date: string = '';
}
