
import { Component, Prop } from 'vue-property-decorator';
import FormComponent from '@/shared/components/layout/form/form-component';
import FormActions from '@/shared/components/layout/form/form-actions.vue';

// module
import { notesTypesModule } from '../../shared/state/notes/module';
// data request type
import { NoteDataRequest } from '@/modules/contractors/shared/requests/note-data-dequest';

import AutoCompleteWithAddNew from '@/shared/components/elements/auto-complete-with-add-new.vue';

import { contactModule } from '../../shared/state/contacts/module';
import ContractorContactForm from '../contacts/form.vue';

import { contractorsModule } from '../../shared/state/module';
import ContractorForm from '../form.vue';
import { callSuper } from '@/shared/helpers';

@Component({
    components: { FormActions, AutoCompleteWithAddNew },
})
export default class ObjectsTypesSettingsForm extends FormComponent<NoteDataRequest> {
    @Prop({ default: false }) public hideContractorField!: boolean;
    public actionsTypes = notesTypesModule.actionsTypes;
    public mutationTypes = notesTypesModule.mutationsTypes;
    public store = this.$store.state.contractorsState.notesTypesModuleState;
    public storeAction = this.actionsTypes.STORE_ITEM;
    public updateAction = this.actionsTypes.UPDATE_ITEM;
    public requestData = new NoteDataRequest();

    public contractorId: null | number = null;
    public contractorContactId: null | number = null;

    // data required for add new type
    public contactsState = this.$store.state.contractorsState.contactsState;
    public contactModule = contactModule;
    public ContractorContactForm = ContractorContactForm;

    public contractorsState = this.$store.state.contractorsState;
    public contractorsModule = contractorsModule;
    public ContractorForm = ContractorForm;

    public created() {
        if (this.itemData.contractor_id) {
            this.contractorId = this.itemData.contractor_id;
        }
    }

    get formTitle(): string {
        return this.edit ? 'Edytuj notatkę' : 'Dodaj notatkę';
    }

    get contractorIdObject(): object {
        return { contractor_id: this.contractorId };
    }

    public async submitForm(e: any, next?: boolean): Promise<void> {
        if (this.itemData.contractor_id) {
            this.contractorId = this.itemData.contractor_id;
        } else if (this.itemData.contractor_contact_id) {
            this.contractorId = this.itemData.contractor_id;
            this.contractorContactId = this.itemData.contractor_contact_id;
        }

        if (this.itemData.contractor_contact_id === 0) {
            this.itemData.contractor_contact_id = null;
        }

        return callSuper(this, 'submitForm', e, next);
    }

    public changeContractorId(id: any) {
        this.contractorId = id;
    }

    public initForm() {
        this.form = this.$refs.createContactForm;
    }
}
