
import { Component, Prop } from 'vue-property-decorator';
import DetailsComponent from '@/shared/components/layout/details/details-component';
import TopToolbar from '@/shared/components/layout/details/top-toolbar.vue';

import { contractorsModule } from '@/modules/contractors/shared/state/module';
import { ContractorDataRequest } from '@/modules/contractors/shared/requests/contractor-data-request';

import ContractorForm from './form.vue';
import ContactsList from './contacts/list.vue';
import NotesList from './notes/list.vue';

import { actionsTypes } from '../../admin/shared/state/types';
import { setGuardianForContractor } from '../shared/services/contractors/contractors';
import TooltipBtn from '@/shared/components/elements/tooltip-btn.vue';
import RelatedInvestmentsList from './related-investments/list.vue';
import RelatedSubjectsList from './related-investment-subjects/list.vue';
import SaleCard from '@/modules/sales/components/list/item.vue';

@Component({
    props: {
        id: String,
    },
    components: {
        TopToolbar,
        ContactsList,
        NotesList,
        ContractorForm,
        TooltipBtn,
        RelatedInvestmentsList,
        RelatedSubjectsList,
        SaleCard,
    },
})
export default class ContactDetails extends DetailsComponent<ContractorDataRequest> {
    @Prop(Number) public contractorId!: number;

    public store = this.$store.state.contractorsState;
    public actionsTypes = contractorsModule.actionsTypes;
    public mutationTypes = contractorsModule.mutationsTypes;
    public showAction = this.actionsTypes.SHOW_ITEM;
    public tab: any = null;
    public users: any = [];
    public guardianId: any = null;
    public guardianLoading: boolean = false;

    get editedItem() {
        return this.store.editedItem;
    }

    get formDialog() {
        return this.store.formDialog;
    }
    set formDialog(newVal) {
        this.$store.commit(this.mutationTypes.UPDATE_FORM_DIALOG, newVal);
    }

    public created() {
        this.fetchDetails();
        this.guardianLoading = true;
        this.$store
            .dispatch(actionsTypes.FETCH_USER_DATA)
            .then((res) => {
                this.users = this.pullToArraysHeadCurrentUser(res.data);
            })
            .finally(() => {
                this.guardianLoading = false;
            });
    }
    public mounted() {
        const idNote = Number(this.$route.params.idNote);
        const currentTab = this.$route.query.currentTab;
        setTimeout(() => {
            if (idNote) {
                this.tab = 1;
            }
            if (currentTab) {
                if (currentTab === 'notes') {
                    this.tab = 1;
                } else if (currentTab === 'contacts') {
                    this.tab = 0;
                }
            }
        }, 200);
    }
    public isPermitted(action: string) {
        return this.permissionCheck(`contractors.${action}`);
    }

    public fetchCallback() {
        this.guardianId = this.item.user_id;
    }

    get currentUser() {
        return this.$store.state.authState.user;
    }

    public pullToArraysHeadCurrentUser(arrayUsers: [any]) {
        const currentArray = [...arrayUsers];
        const { id, name } = this.currentUser;
        const currentUser = {
            ...this.currentUser,
            name: `(Ja) ${name}`,
        };
        const arrayWithoutCurrentUser = this._.filter(currentArray, this._.negate(this._.matches({ id })));
        return [currentUser, ...arrayWithoutCurrentUser];
    }

    public setGuardian(guardianId: any) {
        this.$store.dispatch(this.actionsTypes.SET_GUARD_FOR_CONTRACTOR, {
            contractorId: this.item.id,
            guardianId,
        });
    }

    public editItem() {
        this.$store.commit(this.mutationTypes.UPDATE_EDITED, this.item);
        this.$store.commit(this.mutationTypes.UPDATE_FORM_DIALOG, true);
    }

    public itemSaved() {
        this.formDialog = false;
        this.fetchDetails();
    }
}
