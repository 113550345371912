
import { Component, Prop } from 'vue-property-decorator';
import ListComponent from '@/shared/components/layout/list/list-component';
import { ModuleProps } from '@/shared/state/template/module-props';
import ItemsTable from '@/shared/components/layout/list.vue';
import { ListFilter } from '@/shared/types';

    // module
import { notesTypesModule } from '../../shared/state/notes/module';
    // model
import { Note } from '../../shared/models/notes';
    // data request type
import { NoteDataRequest } from '@/modules/contractors/shared/requests/note-data-dequest';
    // headers
import { notesListHeaders } from '@/modules/contractors/shared/config';

    // components
import NoteForm from './form.vue';
import NoteDetails from './details.vue';
import { callSuper } from '@/shared/helpers';

@Component({
        components: {
            ItemsTable,
            NoteForm,
            NoteDetails,
        },
        props: {
            // contractor_id: Number,
        },
    })
    export default class NoteList extends ListComponent<Note, NoteDataRequest> {
        @Prop({ default: false }) public hideContractorField!: boolean;
        public headers: object[] = notesListHeaders;
        public store = this.$store.state.contractorsState.notesTypesModuleState;
        public actionsTypes = notesTypesModule.actionsTypes;
        public mutationTypes = notesTypesModule.mutationsTypes;
        public fetchAction = this.actionsTypes.FETCH_DATA;
        public removeAction = this.actionsTypes.REMOVE_ITEM;
        public props: ModuleProps = notesTypesModule.moduleProps;
        public parent: any = {};

        public contractorId: null | number = null;
        public contractorContactId: null | number = null;

        public showDialogFromUrl = false;
        public idDialogFromUrl: null | number = null;

        public fetchData() {
            this.fetchParams.filters = {
                ...this.fetchParams.filters,
                contractor_id: this.contractorId,
                contractor_contact_id: this.contractorContactId,
            };
            callSuper(this, 'fetchData', );
        }

        public created() {
            const params = this.$route.params;

            if (params.id) {
                this.contractorId = Number(params.id);
            } else if (params.idContact) {
                this.contractorId = Number(params.idContractor);
                this.contractorContactId = Number(params.idContact);
            }

            this.fetchData();

            const idNote = Number(this.$route.params.idNote);
            if (idNote) {
                this.idDialogFromUrl = idNote;
                this.showDialogFromUrl = true;
            }
        }
    }
