import {InvestmentTag} from '@/modules/investments/shared/models/investment-tag';

export class InvestmentDataRequest {
    public id: number = 0;
    public name?: string = '';
    // tslint:disable-next-line:variable-name
    public start_date: string = '';
    // tslint:disable-next-line:variable-name
    public end_date: string = '';
    // tslint:disable-next-line:variable-name
    public description?: string = '';
    // tslint:disable-next-line:variable-name
    public tags: any[] = [];
    public address: string = '';
    // tslint:disable-next-line:variable-name
    public planned_start_date: string = '';
    // tslint:disable-next-line:variable-name
    public planned_end_date: string = '';
    // tslint:disable-next-line:variable-name
    public build_approval: boolean = false;
    // tslint:disable-next-line:variable-name
    public is_build_approval_final: boolean = false;
    // tslint:disable-next-line:variable-name
    public is_build_approval_contested: boolean = false;
    // tslint:disable-next-line:variable-name
    public build_approval_due_date: string = '';
    // tslint:disable-next-line:variable-name
    public authorization_number: string = '';
    // tslint:disable-next-line:variable-name
    public issuing_authority: string = '';



    // tslint:disable-next-line:variable-name
    public number_of_buildings: number = 0;
    // tslint:disable-next-line:variable-name
    public arrangement_of_buildings: string = '';
    // tslint:disable-next-line:variable-name
    public investment_schedule: string = '';


    // tslint:disable-next-line:variable-name
    // public manufacturing_technology: string = '';
    // tslint:disable-next-line:variable-name
    // public standard_of_area_finishing: string = '';
    // tslint:disable-next-line:variable-name
    // public count_of_stop_subjects: string = '';



    // tslint:disable-next-line:variable-name
    public public_road_access: boolean = false;


    // tslint:disable-next-line:variable-name
    public owned_means: string = '';

    // tslint:disable-next-line:variable-name
    // public owned_means_percentage_share: string = '';

    // tslint:disable-next-line:variable-name
    public owned_means_institutions: string = '';
    // tslint:disable-next-line:variable-name
    public is_bank_bill_open: boolean = false;
    // tslint:disable-next-line:variable-name
    public financial_institution_guarantee: string = '';
    // tslint:disable-next-line:variable-name
    public cash_safety_rules: string = '';
    // tslint:disable-next-line:variable-name
    public institution_of_cash_safety: string = '';

    // tslint:disable-next-line:variable-name
    public allowing_of_valorization: boolean = false;
    // tslint:disable-next-line:variable-name
    public principles_of_valorization: string = '';
    // tslint:disable-next-line:variable-name
    public terms_of_withdrawal_from_the_developer_contract: string = '';
    // tslint:disable-next-line:variable-name
    public media_available: string = '';

    // tslint:disable-next-line:variable-name
    public is_notifiable_according_to_building_law: boolean = false;
    // tslint:disable-next-line:variable-name
    public building_law_notification_number: string = '';
    // tslint:disable-next-line:variable-name
    public guarantee_fund_due_percentage: string = '';
    // tslint:disable-next-line:variable-name
    public neighbourhood_objects_details: string = '';
    // tslint:disable-next-line:variable-name
    public selected_legal_act: string = '';
    // tslint:disable-next-line:variable-name
    public selected_legal_act_details: string = '';
    // tslint:disable-next-line:variable-name
    public has_no_plan: boolean = false;
    // tslint:disable-next-line:variable-name
    public area_use: string = '';
    // tslint:disable-next-line:variable-name
    public max_and_min_buildings_intensity: string = '';
    // tslint:disable-next-line:variable-name
    public max_buildings_height: string = '';
    // tslint:disable-next-line:variable-name
    public min_bioactive_area_percentage: string = '';
    // tslint:disable-next-line:variable-name
    public min_parking_spots_count: string = '';
    // tslint:disable-next-line:variable-name
    public protection_conditions: string = '';
    // tslint:disable-next-line:variable-name
    public flood_threat_requirements: string = '';
    // tslint:disable-next-line:variable-name
    public culture_protection_requirements: string = '';
    // tslint:disable-next-line:variable-name
    public other_areas_protection_requirements: string = '';
    // tslint:disable-next-line:variable-name
    public communication_requirements_and_rules: string = '';
    // tslint:disable-next-line:variable-name
    public technical_infrastructure_requirements_and_rules: string = '';

    // tslint:disable-next-line:variable-name
    public neighbourhood_area_use: string = '';
    // tslint:disable-next-line:variable-name
    public neighbourhood_max_and_min_buildings_intensity: string = '';
    // tslint:disable-next-line:variable-name
    public neighbourhood_max_buildings_height: string = '';
    // tslint:disable-next-line:variable-name
    public neighbourhood_min_bioactive_area_percentage: string = '';
    // tslint:disable-next-line:variable-name
    public neighbourhood_min_parking_spots_count: string = '';

    // tslint:disable-next-line:variable-name
    public buildings_and_land_development_function: string = '';
    // tslint:disable-next-line:variable-name
    public method_of_buildings_and_land_development_use: string = '';

    // tslint:disable-next-line:variable-name
    public dimensions: string = '';
    // tslint:disable-next-line:variable-name
    public architectural_form: string = '';
    // tslint:disable-next-line:variable-name
    public building_line_location: string = '';
    // tslint:disable-next-line:variable-name
    public area_use_intensity: string = '';
    // tslint:disable-next-line:variable-name
    public no_plan_protection_conditions: string = '';
    // tslint:disable-next-line:variable-name
    public no_plan_flood_threat_requirements: string = '';
    // tslint:disable-next-line:variable-name
    public no_plan_culture_protection_requirements: string = '';
    // tslint:disable-next-line:variable-name
    public no_plan_other_areas_protection_requirements: string = '';
    // tslint:disable-next-line:variable-name
    public no_plan_communication_requirements_and_rules: string = '';
    // tslint:disable-next-line:variable-name
    public no_plan_technical_infrastructure_requirements_and_rules: string = '';

    // tslint:disable-next-line:variable-name
    public provided_investments_plan_info: string = '';
    // tslint:disable-next-line:variable-name
    public provided_investments_commune_plan_info: string = '';
    // tslint:disable-next-line:variable-name
    public provided_investments_conditions_decision_info: string = '';
    // tslint:disable-next-line:variable-name
    public provided_investments_environmental_conditions_info: string = '';
    // tslint:disable-next-line:variable-name
    public provided_investments_restricted_use_act_info: string = '';
    // tslint:disable-next-line:variable-name
    public provided_investments_local_reconstruction_plan_info: string = '';
    // tslint:disable-next-line:variable-name
    public provided_investments_flood_threat_maps_info: string = '';

    // tslint:disable-next-line:variable-name
    public public_road_decision: string = '';
    // tslint:disable-next-line:variable-name
    public public_railway_line_location_decision: string = '';
    // tslint:disable-next-line:variable-name
    public public_airport_decision: string = '';
    // tslint:disable-next-line:variable-name
    public public_antiflood_decision: string = '';
    // tslint:disable-next-line:variable-name
    public public_nuclear_object_location_decision: string = '';
    // tslint:disable-next-line:variable-name
    public public_transmission_network_location_decision: string = '';
    // tslint:disable-next-line:variable-name
    public public_regional_broadband_location_decision: string = '';
    // tslint:disable-next-line:variable-name
    public cpk_dependent_investment_location_decision: string = '';
    // tslint:disable-next-line:variable-name
    public public_access_infrastructure_decision: string = '';
    // tslint:disable-next-line:variable-name
    public public_oil_industry_location_decision: string = '';

    // tslint:disable-next-line:variable-name
    public bank_consent_information: string = '';
    // tslint:disable-next-line:variable-name
    public specific_bank_consent_information: string = '';
    // tslint:disable-next-line:variable-name
    public escrow_account_bank_name: string = '';
    // tslint:disable-next-line:variable-name
    public escrow_bank_trademark: string = '';

    // tslint:disable-next-line:variable-name
    public way_of_subject_measurement: string = '';


}

