
import ItemsTable from '@/shared/components/layout/list.vue';
import { Component, Prop } from 'vue-property-decorator';
import ListComponent from '@/shared/components/layout/list/list-component';
import InvestmentsForm from '@/modules/investments/components/form.vue';
import { ModuleProps } from '@/shared/state/template/module-props';
import { investmentTagsModule } from '@/modules/investments/shared/state/submodules';
import InvestmentTagChip from '@/modules/investments/components/settings/tags/tag.vue';
import TotalCosts from '@/modules/investments/components/total-costs.vue';
import { costStatuses } from '@/shared/config/costs';
import { relatedInvestmentsModule } from '../../shared/state/related-investments/module';
import { RelatedInvestmentDataRequest } from '../../shared/requests/related-investments-data-request';
import { investmentsListHeaders } from '@/modules/investments/shared/config';
import { Investment } from '@/modules/investments/shared/models/investment';
import RelatedInvestmentsForm from './form.vue';
import { ToolbarProps } from '@/shared/models/toolbar-props';

@Component({
    components: {
        ItemsTable,
        InvestmentTagChip,
        InvestmentsForm,
        TotalCosts,
        RelatedInvestmentsForm,
    },
})
export default class RelatedInvestmentsList extends ListComponent<Investment, RelatedInvestmentDataRequest> {

    get investmentTags() {
        return this.store.investmentTagsState.dataList;
    }
    public headers: object[] = investmentsListHeaders.filter((el) => el.value !== 'index');
    public actionsTypes = relatedInvestmentsModule.actionsTypes;
    public tagsActions = investmentTagsModule.actionsTypes;
    public mutationTypes = relatedInvestmentsModule.mutationsTypes;
    public fetchAction: string = this.actionsTypes.FETCH_DATA;
    public removeAction: string = this.actionsTypes.REMOVE_ITEM;
    public store: any = this.$store.state.contractorsState.relatedInvestmentsState;
    public props: ModuleProps = relatedInvestmentsModule.moduleProps;
    public currentStatus = 0;
    public costStatuses = costStatuses.filter((status) => ['new', 'approved', 'settled'].includes(status.id));

    public toolbarProps = new ToolbarProps();

    public created() {
        this.id = parseInt(this.$route.params.id, 10);
        this.setToolbarProps();
    }

    public showForm() {
        this.editItem({
            contractor_id: parseInt(this.$route.params.id, 10),
            investments: this.items.map((el) => el.id),
        });
        this.formDialog = true;
    }

    public deleteItem(item: Investment) {
        this.current = { ...item, contractor_id: this.id };
        this.removeDialog = true;
    }

    public onUpdate() {
        setTimeout(() => {
            this.fetchData();
        }, 100);
    }

    private setToolbarProps() {
        this.$set(this.toolbarProps, 'addButtonTitle', 'Edytuj');
    }
}
