
    import { Component, Vue } from 'vue-property-decorator';
    import NotesList from './list.vue';

    @Component({
        components: {
            NotesList,
        },
    })
    export default class AllNotesList extends Vue {}
