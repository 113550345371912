
import ItemsTable from '@/shared/components/layout/list.vue';
import { Component, Prop } from 'vue-property-decorator';
import { RelatedInvestmentSubject } from '@/modules/investments/shared/models/investment-subject';
import { relatedInvestmentSubjectListHeaders } from '@/modules/investments/shared/config';
import ListComponent from '@/shared/components/layout/list/list-component';
import { ModuleProps } from '@/shared/state/template/module-props';
import { relatedInvestmentSubjectsModule } from '../../shared/state/related-subjects/module';
import { Store } from 'vuex';
import { privateStatuses, publicStatuses } from '@/shared/config/investments';
import { ToolbarProps } from '@/shared/models/toolbar-props';
import RelatedInvestmentSubjectsForm from './form.vue';
import { RelatedInvestmentSubjectDataRequest } from '../../shared/requests/related-investment-subjects-data-request';
import { investmentSubjectOperationsModule } from '@/modules/investments/shared/state/submodules';
@Component({
    components: {
        ItemsTable,
        RelatedInvestmentSubjectsForm,
    },
})
export default class RelatedSubjectsList extends ListComponent<RelatedInvestmentSubject,
    RelatedInvestmentSubjectDataRequest> {
    @Prop(Number) public contractorId!: number;
    public headers: object[] = relatedInvestmentSubjectListHeaders;
    public props: ModuleProps = relatedInvestmentSubjectsModule.moduleProps;
    public store = this.$store.state.contractorsState.relatedInvestmentSubjectsState;
    public actionsTypes = relatedInvestmentSubjectsModule.actionsTypes;
    public removeAction: string = this.actionsTypes.REMOVE_ITEM;
    public mutationTypes = relatedInvestmentSubjectsModule.mutationsTypes;
    public fetchAction = this.actionsTypes.FETCH_DATA;
    public toolbarProps = new ToolbarProps();
    public subjectActionsTypes = investmentSubjectOperationsModule.actionsTypes;

    public created() {
        this.toolbarProps.addButton = false;
    }

    public fetchData() {
        this.$store.dispatch(this.fetchAction, {id: this.contractorId})
        .then((data: any) => {
            this.store.data = data.data;
        });
    }

    public loaded() {
        this.store.loadingItem = false;
    }

    public itemPrivateStatus(item: RelatedInvestmentSubject) {
        return privateStatuses.find((status) => status.value === item.private_status);
    }
    public itemPublicStatus(item: RelatedInvestmentSubject) {
        return publicStatuses.find((status) => status.value === item.public_status);
    }

}

