
import { httpClient } from '@/shared/services';
import { Component, Vue } from 'vue-property-decorator';
import { SaleStatus, salesModule } from '@/modules/sales/shared';

@Component({
    props: {
        status: Object,
        small: Boolean,
        outlined: Boolean,
        saleId: Number,
        sale: Object,
        disabled: Boolean,
        readOnly: {
            type: Boolean,
            default: false,
        },
    },
})
export default class StatusesPicker extends Vue {
    public menu = false;
    public statuses = [];
    public beforeLoadedChip = { name: 'Brak', id: 1 };
    public chosenOption: null | SaleStatus = null;
    public mutationTypes = salesModule.mutationsTypes;
    public props = salesModule.moduleProps;
    public isLoading = false;

    public fetchStatuses() {
        this.isLoading = true;
        httpClient
            .get(`/api/v1/${this.props.mockURLName || this.props.name}/statuses`)
            .then((res) => res.data.data)
            .then((data) => {
                this.statuses = data;
            })
            .catch(() => {
                this.$store.commit('SHOW_SNACKBAR', { type: 'error', text: 'Coś poszło nie tak' });
            })
            .finally(() => {
                this.isLoading = false;
            });
    }

    get user() {
        return this.$store.state.authState.user;
    }

    public changeStatus() {
        const saleId = this.$props.saleId || this.$route.params.id;
        httpClient
            .put(`/api/v1/${this.props.mockURLName || this.props.name}/${saleId}`, {
                sale_status_id: this.activeStatus?.id,
            })
            .then(({ data }) => {
                if (data.data) {
                    this.$store.commit('SHOW_SNACKBAR', { type: 'success', text: 'Status zmieniony' });
                    this.$store.commit(this.mutationTypes.UPDATE_CURRENT, data.data);
                }
            });
    }

    get activeStatus() {
        return this.chosenOption ? this.chosenOption : this.$props.status ? this.$props.status : this.beforeLoadedChip;
    }

    // public created() {
    //     this.fetchStatuses();
    // }
}
